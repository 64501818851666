import React, { useEffect, useState, useRef, useCallback } from "react";
import classes from "./artist.module.css";
import { useStateContext } from "../../context/context";
import Toggle from "../toggle/toggle";
import { ArrowLeft, ArrowUp } from "react-feather";
import parse from "html-react-parser";
import { useLocation, useNavigate } from "react-router-dom"; // For extracting query parameters
import Popularity from "../popularity/popularity";
import Sorting from "../sorting/sorting";
import Toast from "../toast/toast";
import List from "../list/list";
import Album from "../album/album";
function Artist(props) {
  const {
    artist,
    setArtist,
    artistAlbums,
    includeSingels,
    setIncludeSingels,
    artistInfo,
    getArtist,
    searchTerm,
    loading,
    scrollToTop,
    albumOfsett,
    setAlbumOffset,
    getArtistAlbums,
    showToast,
    closeToast,
    messages,
    setMessages,
    isSticky,
    popularArtists,
  } = useStateContext();
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const [loadingMore, setLoadingMore] = useState(false);
  const [sorting, setSorting] = useState("new");
  const observerRef = useRef();
  const debounceTimeoutRef = useRef();
  const totalItems = artistAlbums?.total || 0;
  const hasMoreItems = totalItems > artistAlbums?.items?.length;
  const maxItems = 100;
  const [showList, setShowList] = useState(false); // State to control visibility of List

  useEffect(() => {
    // Delay the display of the List after the component mounts
    const timer = setTimeout(() => {
      if (!loading && artistInfo) {
        setShowList(true); // Set to true after a delay
      }
    }, 500); // Adjust the delay (in milliseconds) as needed

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, [loading, artistInfo]);

  useEffect(() => {
    setAlbumOffset(0);
  }, []);

  const loadMoreItems = useCallback(async () => {
    if (!hasMoreItems) {
      showToast(`No new music found.`);
    }
    if (loading || loadingMore || !hasMoreItems) return; // Prevent unnecessary calls if already loading or no more items

    setAlbumOffset((prevOffset) => {
      const newOffset = prevOffset + 20;

      // Check if the new offset exceeds the maximum allowed items
      if (newOffset >= maxItems) {
        return prevOffset; // Return previous offset since we won't fetch more
      }

      // Trigger loading more items
      getArtistAlbums(artist?.id, newOffset) // Await the result of the album fetch
        .then((newAlbums) => {
          const newAlbumsCount =
            newAlbums?.items.filter((item) => item.album_type === "album")
              .length || 0;
          const newSinglesCount =
            newAlbums?.items.filter((item) => item.album_type === "single")
              .length || 0;

          // Show a toast with the counts of new albums and singles
          const totalItems = newAlbums?.items.length || 0;
          if (totalItems) {
            showToast(
              `${totalItems} found. ${newAlbumsCount} new album(s) and ${newSinglesCount} new single(s).`
            );
          } else {
            showToast(`No new music found.`);
          }
        })
        .catch((error) => {
          console.error("Error fetching albums:", error);
          showToast("Error fetching albums."); // Show error toast
        });

      return newOffset; // Update offset to trigger loading more
    });
  }, [
    loading,
    loadingMore,
    artist?.id,
    setAlbumOffset,
    getArtistAlbums,
    hasMoreItems,
    maxItems,
    artistAlbums,
  ]);

  const expandSearch = () => {
    scrollToTop();
    loadMoreItems();
  };
  const buttonRef = useRef(null); // To reference the search component

  const lastItemRef = useCallback(
    (node) => {
      if (loading || loadingMore || !hasMoreItems) return; // Guard clause
      if (observerRef.current) observerRef.current.disconnect(); // Disconnect old observer

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // Clear the previous debounce timeout if still active
          if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
          }
          // Debounce the loadMoreItems call
          debounceTimeoutRef.current = setTimeout(loadMoreItems, 300);
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [
      loading,
      loadingMore,
      searchTerm,
      setAlbumOffset,
      getArtistAlbums,
      hasMoreItems,
      loadMoreItems,
    ]
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const artistId = searchParams.get("id");

  useEffect(() => {
    if (artistId && !loading) {
      getArtist(artistId);
    }
  }, [artistId]);

  const removeArtist = () => {
    setArtist(null);
    scrollToTop();
    navigate(searchTerm ? `/search?q=${searchTerm}` : `/search`);
  };

  if (!artistAlbums || !artist) {
    return null;
  }
  const genreColors = (genre) => {
    const genreColorMap = {
      rock: "#FF5733", // Vivid Red
      pop: "#33FF57", // Bright Green
      disco: "#FF33A1", // Hot Pink
      blues: "#3357FF", // Strong Blue
      jazz: "#4caf50", // Classic Green
      metal: "#FF8C33", // Warm Orange
      reggae: "#16A085", // Sea Green
      electronic: "#2980B9", // Deep Sky Blue
      classical: "#F39C12", // Bright Gold
      country: "#FF6F61", // Coral Pink
      hiphop: "#9B59B6", // Light Purple
      hip: "#9B59B6", // Light Purple
      rap: "#9B59B6", // Light Purple (same as hiphop)
      folk: "#8E44AD", // Rich Purple
      punk: "#E74C3C", // Soft Red
      indie: "#FF8C33", // Warm Orange (similar to metal)
      alternative: "#E67E22", // Vibrant Orange
      soul: "#D35400", // Deep Orange
      funk: "#C0392B", // Dark Red
      "r&b": "#884EA0", // Dark Purple
      techno: "#2E86C1", // Sky Blue
      house: "#2874A6", // Deep Blue
      trance: "#48C9B0", // Bright Teal
      "latin pop": "#F7DC6F", // Bright Yellow
      ska: "#1ABC9C", // Turquoise
      grunge: "#7D3C98", // Dark Purple
      gospel: "#FFDEAD", // Pale Yellow
      opera: "#E5E7E9", // Light Grey
      kpop: "#FF99CC", // Pastel Pink
      soundtrack: "#1d3ece", // Bold Blue
    };

    // Helper function to calculate brightness of the background color
    const getBrightness = (color) => {
      // Remove the hash (#) if present
      color = color.replace("#", "");

      // Convert hex color to RGB
      const r = parseInt(color.slice(0, 2), 16);
      const g = parseInt(color.slice(2, 4), 16);
      const b = parseInt(color.slice(4, 6), 16);

      // Use the formula for brightness: Y = 0.299*R + 0.587*G + 0.114*B
      return 0.299 * r + 0.587 * g + 0.114 * b;
    };

    // Convert genre to lowercase and remove extra spaces
    const normalizedGenre = genre.toLowerCase().trim();

    // First, check for an exact match
    if (genreColorMap[normalizedGenre]) {
      const bgColor = genreColorMap[normalizedGenre];

      // Get brightness of the background color
      const brightness = getBrightness(bgColor);

      // If brightness is below a certain threshold, use white text; otherwise, use dark text
      const textColor = brightness < 128 ? "#fff" : "#111"; // White text for dark colors, dark text for light colors

      return { backgroundColor: bgColor, textColor };
    }

    // If no exact match, check for partial match
    const partialMatchKey = Object.keys(genreColorMap).find((key) =>
      normalizedGenre.includes(key)
    );

    const bgColor = genreColorMap[partialMatchKey] || "#999"; // Light Grey as default

    // Get brightness of the background color
    const brightness = getBrightness(bgColor);

    // Determine appropriate text color based on brightness
    const textColor = brightness < 128 ? "#fff" : "#111"; // White text for dark colors, dark text for light colors

    return { backgroundColor: bgColor, textColor };
  };

  const imageSrc =
    artist?.images.length > 0
      ? artist?.images?.[0]?.url ||
        artist?.images?.[1]?.url ||
        artist?.images?.[2]?.url
      : false;
  const artistDetails = (
    <div className={classes.artistInfo}>
      <div
        className={`${classes.mobileControl} ${
          isSticky ? classes.mobileControlIsSticky : ""
        }`}
        ref={buttonRef}
      >
        <button onClick={removeArtist} className={classes.backButton}>
          <ArrowLeft /> View Artists
        </button>
        <button onClick={scrollToTop} className={classes.upArrow}>
          <ArrowUp />
        </button>
      </div>
      <div className={classes.info}>
        <img src={imageSrc} alt={`Image representing ${artist?.name}`} />

        <div className={classes.infoRight}>
          <h2> {artist?.name}</h2>
          {/* <h2> {artist?.id}</h2> */}

          <div className={classes.popularity}>
            <h3>Popularity: </h3>{" "}
            <Popularity popularity={artist?.popularity} size="big" />
          </div>
          {artist?.genres?.length > 0 ? (
            <div className={classes.genres}>
              Genres:{" "}
              {artist?.genres?.map((item, index) => {
                const { backgroundColor, textColor } = genreColors(item); // Get background and text colors for the genre

                return (
                  <p
                    key={index}
                    className={classes.genreItem}
                    style={{
                      backgroundColor: backgroundColor,
                      color: textColor,
                    }} // Apply both background and text colors
                  >
                    {item}
                  </p>
                );
              })}
              <p>
                {artistInfo?.artist?.bio?.summary
                  ? parse(artistInfo?.artist?.bio?.summary)
                  : "No Bio"}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );

  // Filter albums based on toggle state
  const filteredAlbums = artistAlbums?.items.filter((item) => {
    const isArtistIncluded = item?.artists?.some(
      (a) => a?.name === artist?.name
    );
    return (includeSingels || item.album_type === "album") && isArtistIncluded;
  });

  // Sort albums by release date (newest first)
  const sortedAlbums = filteredAlbums?.sort((a, b) => {
    if (sorting === "old") {
      return new Date(a.release_date) - new Date(b.release_date); // Newest first
    } else {
      return new Date(b.release_date) - new Date(a.release_date); // Newest first
    }
  });

  // Group albums by release year
  const albumsByYear = sortedAlbums?.reduce((acc, album) => {
    const year = new Date(album.release_date).getFullYear();
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(album);
    return acc;
  }, {});

  // Create an array from the object for easier manipulation
  const yearEntries = Object.entries(albumsByYear).sort((a, b) => {
    if (sorting === "old") {
      return a[0] - b[0];
    } else {
      return b[0] - a[0];
    }
  }); // Sort years in descending order
  let totalAlbums = sortedAlbums.length; // Get the total number of albums
  const albumsCount =
    artistAlbums?.items.filter((item) => item.album_type === "album").length ||
    0;
  let albumCounter = 1; // Initialize counter outside the map functions

  const albumList = (
    <div>
      {yearEntries.map(([year, albums], mainIndex) => {
        // Determine the next year in the sorted list, if it exists
        const thisYear = parseInt(year);
        const previousYear = parseInt(yearEntries[mainIndex - 1]);
        const yearsUntilNextAlbum = previousYear - thisYear || 0;
        return (
          <div key={year}>
            <div className={classes.timeContainer}>
              <h3 className={classes.yearHeading}>{year}</h3>
              <p>-</p>
              <p>
                {yearsUntilNextAlbum
                  ? `${Math.abs(yearsUntilNextAlbum)} years until next release`
                  : sorting === "old" ? "Earliest Release" : "Latest Release"}
              </p>
            </div>

            <ul className={classes.albumList}>
              {albums.map((item, index) => {
                const albumNum = albumCounter; // Store the current value of the counter
                albumCounter += 1; // Increment the counter

                return (
                  <li
                    key={item?.id + "album"}
                    className={classes.albumItem}
                    style={{ animationDelay: `${0.1 + index * 0.1}s` }}
                  >
                    <Album
                      album={item}
                      num={albumNum}
                      mainArtist={artist.name}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}

      {!loading ? (
        <div className={classes.extendSearch}>
          <p>
            We can try to do a more exstensive search if you didn't find what
            you were looking for.
          </p>
          <button onClick={expandSearch}>Find More Music</button>
        </div>
      ) : null}
    </div>
  );

  if (!artistAlbums || !artistInfo) {
    return (
      <div className={classes.container}>
        <div className={`${classes.artistInfo} ${classes.shimmer}`}></div>
        <div className={classes.shimmerContainer}>
          <p></p>

          <ul className={`${classes.albumListShimmer}`}>
            <li
              className={`${classes.albumCardShimmer} ${classes.shimmer}`}
            ></li>
            <li
              className={`${classes.albumCardShimmer} ${classes.shimmer}`}
            ></li>
            <li
              className={`${classes.albumCardShimmer} ${classes.shimmer}`}
            ></li>
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.container}>
        {artistDetails}
        <div className={classes.controls}>
          <div className={classes.toggleContainer}>
            <p>Include singles</p>
            <Toggle
              setState={setIncludeSingels}
              defaultState={includeSingels}
            />
          </div>
          <Sorting sorting={sorting} setSorting={setSorting} />
        </div>
        <div className={classes.amount}>
          {!includeSingels ? (
            <p>{`Showing ${albumsCount} albums`}</p>
          ) : (
            <p>{`Showing ${artistAlbums?.items?.length} albums and singels`}</p>
          )}
        </div>

        {albumList}
        <div
          className={`${classes.listContainer} ${
            showList ? classes.visible : ""
          }`}
        >
          <List label="Popular Searches" items={popularArtists} />
        </div>
        {messages.length > 0 && (
          <Toast messages={messages} onClose={closeToast} />
        )}
      </div>
    );
  }
}
export default Artist;
