import React, { useEffect } from "react";
import List from "../list/list";
import { useStateContext } from "../../context/context";
function MainList(props) {
  const { loading, popularArtists } = useStateContext();
  return (
    <>
      <List label="Search results" />
      <List label="Popular Searches" items={popularArtists} />

    </>
  );
}

export default MainList;
