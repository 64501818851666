import React from 'react';
import classes from './spinner.module.css';  // Import the CSS module

const Spinner = () => {
  return (
    <div className={classes.spinnerContainer}>
      <div className={classes.spinnerBg}></div>
      <div className={classes.spinner1}></div>
    </div>
  );
};

export default Spinner;
