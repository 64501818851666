import React, { useEffect } from 'react';
import classes from './toast.module.css';

const Toast = ({ messages, onClose }) => {
  return (
    <div className={classes.toastContainer}>
      {messages.map((message, index) => (
        <div key={index} className={classes.toastMessage}>
          {message.text}
     
        </div>
      ))}
    </div>
  );
};

export default Toast;
