import React, { useCallback } from "react";
import classes from "./listItem.module.css";
import { useStateContext } from "../../context/context";
import { useNavigate } from "react-router-dom";
import Popularity from "../popularity/popularity";

const ListItem = React.memo((props) => {
  const { item } = props;
  const { setArtist, setSearchOffset, scrollToTopInstant } = useStateContext();
  const navigate = useNavigate();

  const handleClickArtist = useCallback(() => {
    setArtist(item);
    scrollToTopInstant();
    navigate(`/artist?id=${item.id}`);
    setSearchOffset(0);
  }, [item, setArtist, setSearchOffset, scrollToTopInstant, navigate]);

  // Use srcSet for responsive image loading based on resolution
  const imageSrc = item?.images?.[0]?.url || null;
  const imageSrcSet = item?.images?.length > 1
    ? `${item?.images?.[2]?.url} 320w, ${item?.images?.[1]?.url} 640w, ${item?.images?.[0]?.url} 1024w`
    : null;

  return (
    <div className={classes.container} onClick={handleClickArtist}>
      {imageSrc ? (
        // Lazy load the image with srcSet for different resolutions
        <img
          className={classes.image}
          src={imageSrc} // Fallback to the highest resolution image
          srcSet={imageSrcSet} // Provide options for lower resolutions
          sizes="(max-width: 320px) 280px, (max-width: 640px) 600px, 1024px"
          alt={`Cover image for the artist profile of ${item?.name}`}
          loading="lazy"
        />
      ) : (
        <div className={classes.imagePlaceholder}>No image</div>
      )}
      <div className={classes.textContainer}>
        <h3 className={classes.name}>{item?.name}</h3>
      </div>
    </div>
  );
});

export default ListItem;
