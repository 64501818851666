import React, { useEffect, useState, useRef } from "react";
import classes from "./search.module.css"; // Import the CSS module
import { useStateContext } from "../../context/context";

function Search(props) {
  const [input, setInput] = useState("");
  const searchRef = useRef(null); // To reference the search component
  const {
    getSearch,
    searchTerm,
    setSearchTerm,
    scrollToTop,
    setSearchOffset,
    isSticky,
    setIsSticky,
    artist,
  } = useStateContext();

  useEffect(() => {
    let timeoutId = null;
  
    const handleScroll = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        const searchComponent = searchRef.current;
        const topPosition = searchComponent.getBoundingClientRect().top;
  
        if (topPosition <= 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }, 5); // Adjust the delay (in milliseconds) as necessary
    };
  
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      ref={searchRef} // Reference the div to track its position
      className={`${classes.container} ${isSticky ? classes.stickyActive : ""}`}
    >
      <h2 className={`${isSticky ? classes.stickyArtistName : classes.hideStickyArtistName}`}>{artist?.name}</h2>

      <div
        className={`${classes.searchBackground} ${
          isSticky ? classes.stickyActiveBackground : ""
        }`}
      >
        <div className={classes.inputContainer}>
          <input
            aria-label="Search for an artist"
            size={50}
            placeholder="Search for an artist"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setSearchOffset(0);
                getSearch(searchTerm);
                scrollToTop();
              }
            }}
          ></input>
        </div>
        <button
          onClick={() => {
            setSearchOffset(0);
            getSearch(searchTerm);
            scrollToTop();
          }}
          className={classes.searchButton}
        >
          Search
        </button>
      </div>
    </div>
  );
}

export default Search;
