import React from 'react';
import classes from "./popularity.module.css";

function Popularity({ popularity, size = "small" }) {
    // Function to determine the color based on the number of filled dots

    // Determine how many dots to fill based on popularity
    const getFilledDots = (popularity) => {
        if (popularity >= 80) return 5; // 5 filled dots for very high popularity
        if (popularity >= 60) return 4; // 4 filled dots for high popularity
        if (popularity >= 40) return 3; // 3 filled dots for medium popularity
        if (popularity >= 20) return 2; // 2 filled dots for low popularity
        return 1; // 1 filled dot for very low popularity
    };

    const filledDots = getFilledDots(popularity);

    return (
        <div className={classes.popularity}>
            {/* Render the dots */}
            {[...Array(filledDots)].map((_, index) => (
                <span
                    key={index}
                    className={`${size == "big" ? classes.bigDot : classes.dot }`}
                    
                />
            ))}
        </div>
    );
}

export default Popularity;
