import React, { useEffect, useState } from "react";
import "./App.css";
import Search from "./components/search/search";
import List from "./components/list/list";
import { StateProvider, useStateContext } from "./context/context";
import Artist from "./components/artist/artist";
import classes from "./app.module.css";
import Footer from "./components/footer/footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useLocation } from "react-router-dom"; // For extracting query parameters
import Spinner from "./components/loading/spinner";
import MainList from "./components/layout/main";
// Register service worker manually
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker
//       .register('/service-worker.js') // Hardcoded path to service worker
//       .then((registration) => {
//         console.log('ServiceWorker registration successful with scope: ', registration.scope);
//       })
//       .catch((error) => {
//         console.log('ServiceWorker registration failed: ', error);
//       });
//   });
// }

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      // Loop through all active service workers and unregister them
      registrations.forEach((registration) => {
        registration.unregister().then((boolean) => {
          if (boolean) {
            console.log('ServiceWorker unregistered successfully.');
          } else {
            console.log('ServiceWorker unregistration failed.');
          }
        });
      });
    }).catch((error) => {
      console.log('Error fetching service workers for unregistration: ', error);
    });
  });
}


function App() {
  return (
    <Router>
      <StateProvider>
        <InnerApp />
      </StateProvider>
    </Router>
  );
}

function InnerApp() {
  const {
    token,
    setToken,
    artist,
    popularArtists,
    getClientToken,
    setSearchTerm,
    getSearch,
    loading,
    artistInfo
  } = useStateContext();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get("q");

  useEffect(() => {
    if (searchQuery) {
      getSearch(searchQuery);
      setSearchTerm(searchQuery);
    }
  }, []);
  // State for the random color
  const [color, setColor] = useState("#ffffff"); // Default to white or any initial color

  // Function to request an access token using Client Credentials Flow
  useEffect(() => {
    const savedToken = window.localStorage.getItem("token");

    if (savedToken) {
      setToken(savedToken);
    } else {
      getClientToken(); // Fetch a new token if there's no saved token
    }
  }, [setToken]); // You don't need to include `token` in the dependency array

  // Function to generate a random color
  const generateRandomColor = () => {
    const colors = [
      "#FF5733", // Vivid Red
      "#33FF57", // Bright Green
      "#3357FF", // Strong Blue
      "#F0F033", // Bright Yellow
      "#FF33A1", // Hot Pink
      "#33F0A1", // Light Green-Blue
      "#FF8C33", // Warm Orange
      "#4caf50", // Classic Green (Similar to what you wanted)
      "#FF6F61", // Coral Pink
      "#8E44AD", // Rich Purple
      "#16A085", // Sea Green
      "#F39C12", // Bright Gold
      "#2980B9", // Deep Sky Blue
      "#E74C3C", // Soft Red
      "#9B59B6", // Light Purple
    ];

    return colors[Math.floor(Math.random() * colors.length)];
  };

  useEffect(() => {
    if (location.pathname === "/search" || location.pathname === "/") {
      document.title = "Albums Sorted In Order";
    } else {
      document.title = `${artist ? artist.name : "Albums"} Sorted In Order`;
    }

    // Update the color whenever the artist changes
    setColor(generateRandomColor());
  }, [location]);

  
  const content = token ? (
    <main className={classes.content}>
      <Search />
      <Routes>
        <Route path="*" element={<MainList />} />
        <Route path="/search" element={<MainList />} />
        <Route path="/" element={<MainList />} />
        <Route path="/artist" element={<Artist />} />
      </Routes>
    </main>
  ) : (
    <main className={classes.content}>
      <Spinner />
    </main>
  );
  const defaultH1 = "ALBUMS";
  return (
    <div className={classes.container}>
      <header>
        <h1 className={classes.header}>
          <span className={classes.artistName} style={{ color: color }}>
            {artist
              ? artist.name.split(/(\s+)/).map((char, index) => (
                  <span
                    key={`artist-${index}`}
                    className={
                      char === " " ? classes.space : classes.animatedLetter
                    }
                    style={{ animationDelay: `${index * 0.03}s` }}
                  >
                    {char === " " ? "\u00A0" : char} {/* Preserve spaces */}
                  </span>
                ))
              : defaultH1.split(/(\s+)/).map((char, index) => (
                  <span
                    key={`defaultH1-${index}`}
                    className={
                      char === " " ? classes.space : classes.animatedLetter
                    }
                    style={{ animationDelay: `${index * 0.03}s` }}
                  >
                    {char === " " ? "\u00A0" : char} {/* Preserve spaces */}
                  </span>
                ))}
          </span>
          <span className={classes.inOrder}>
            {"Sorted In Order".split("").map((char, index) => (
              <span
                key={`inorder-${index}`}
                className={classes.animatedLetter}
                style={{
                  animationDelay: `${
                    (artist ? artist.name.length : defaultH1.length) * 0.03 +
                    index * 0.03
                  }s`,
                }}
              >
                {char === " " ? "\u00A0" : char}
              </span>
            ))}
          </span>
        </h1>
      </header>
      {content}
      <Footer />
    </div>
  );
}

export default App;
