import React from "react";
import classes from "./footer.module.css";
import { ReactComponent as SpotifyLogo } from "../../assets/Full_Logo_Green_RGB.svg";
import { ReactComponent as Logo } from "../../assets/logo512.svg";

function Footer(props) {
  return (
    <footer className={classes.footer}>
      <div className={classes.footerLeft}>
        <h3>
    
          <Logo width={48} height={48} className={classes.desktopLogo} />
          About Us
        </h3>
        <p>
          Hey there! I'm Anton Eriksson, and this platform was born out of my
          love for music and the thrill of discovering albums in the order they
          were released. Sometimes it's fascinating to see how an artist
          evolves, right? This app helps you dive deep into an artist's journey
          by structuring their albums chronologically—no more guessing which
          record came first!
        </p>
        <p>
          Using the Spotify API to sort albums and Last.fm to serve up juicy
          artist details, it's a fun and useful way to explore music history in
          a clear, organized manner. Whether you're a seasoned audiophile or
          just curious, you'll find something interesting here!
        </p>
        {/* <div className={classes.spotifyLogo}>
          <SpotifyLogo />
        </div> */}
      </div>

      <div className={classes.footerRight}>
        <div className={classes.socialIcon}>
          <a
            href="https://github.com/AntonErikssonCode"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="GitHub profile"
          >
            <i className="fab fa-github"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/antoneriksson-chalmers/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn profile of Anton Eriksson"
          >
            <i className="fab fa-linkedin"></i>
          </a>
        </div>
      </div>

      <div className={classes.footerBottom}>
        <p>
          &copy; 2024 Albums in Order. Made by Anton Eriksson. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
