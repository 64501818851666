import classes from "./album.module.css"
import { useState, useEffect } from "react";
import { useStateContext } from "../../context/context";
function Album(props) {
    const { album, num, mainArtist } = props;
    // Get other artists excluding the main artist
    const otherArtists = album.artists
      .filter((a) => a.name !== mainArtist)
      .map((a) => a.name)
      .join(", "); // Join the names with a comma
    const [isOpen, setIsOpen] = useState(false);
    const [albumInfo, setAlbumInfo] = useState(null);
    const { getArtistAlbum } = useStateContext();
  
    useEffect(() => {
      const fetchAlbum = async () => {
        if (isOpen && !albumInfo) {
          const albumData = await getArtistAlbum(album?.id);
          if (albumData) {
            setAlbumInfo(albumData); // Set album data in state
          }
        }
      };
  
      fetchAlbum(); // Call the fetch function if albumId is provided
    }, [isOpen]); // Fetch album only when isOpen changes
  
    const handleToggle = (e) => {
      setIsOpen((prev) => !prev);
    };
  
    function formatDuration(duration_ms) {
      const minutes = Math.floor(duration_ms / 60000); // Convert ms to minutes
      const seconds = Math.floor((duration_ms % 60000) / 1000); // Convert remaining ms to seconds
  
      // Format seconds to always have two digits
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  
      return `${minutes}:${formattedSeconds}`; // Return the formatted time
    }
  
    const tracks = albumInfo?.tracks?.items ? (
      <ul className={classes.tracksContainer}>
        {albumInfo?.tracks?.items.map((track, index) => {
          return (
            <li key={track?.id + "track"}>
              <span className={classes.trackInfo}>
                <strong> {track?.track_number}</strong>
                <span> {track?.name}</span>
              </span>
              <span className={classes.duration}>
                {formatDuration(track?.duration_ms)}{" "}
              </span>
            </li>
          );
        })}
      </ul>
    ) : null;
    return (
      <div className={classes.cardHover}>
        <div className={classes.albumCard} onClick={handleToggle}>
          <div className={classes.imageWrapper}>
            <img
              className={classes.image}
              src={album?.images?.[0]?.url || album?.images?.[1]?.url}
              alt={`Albumcover for ${album?.name}`}
            />
          </div>
  
          <div className={classes.albumDetails}>
            <div className={classes.headerRow}>
              <h3 className={classes.albumName}>{album?.name}</h3>
              <span className={classes.albumType}>{album?.album_type}</span>
            </div>
            <div className={classes.detailsRow}>
              <span className={classes.releaseDate}>{album?.release_date}</span>
              <h4 className={classes.totalTracks}>
                {album?.total_tracks} tracks
              </h4>
            </div>
  
            <h4 className={classes.albumNumber}> {num}</h4>
            {otherArtists && (
              <h4 className={classes.otherArtists}>Featuring: {otherArtists}</h4>
            )}
            <a
              href={album?.external_urls.spotify}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.spotifyLink}
            >
              Listen on Spotify
            </a>
          </div>
          {/* Sliding div for more details */}
        </div>
  
        <div
          className={`${classes.slideOutDetails} ${isOpen ? classes.open : ""}`}
        >
          {tracks}
        </div>
      </div>
    );
  }
  
  export default Album;
  