import React from 'react';
import classes from './sorting.module.css';

const Sorting = ({ sorting, setSorting }) => {
  return (
    <div className={classes.sortingControl}>
      <label>Sort by</label>
      <div className={classes.buttonGroup}>
        <button
          onClick={() => setSorting('new')}
          className={`${classes.buttonStyle} ${sorting === 'new' ? classes.activeButton : ''}`}
        >
          Newest First
        </button>
        <button
          onClick={() => setSorting('old')}
          className={`${classes.buttonStyle} ${sorting === 'old' ? classes.activeButton : ''}`}
        >
          Oldest First
        </button>
      </div>
    </div>
  );
};

export default Sorting;
