// src/context/context.js

import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

// Create a context
const StateContext = createContext();

const SEARCH_ENPOINT = "https://api.spotify.com/v1/search";
const ARTIST_ENPOINT = "https://api.spotify.com/v1/artists";
const ALBUMS_ENPOINT = "https://api.spotify.com/v1/albums";

const TYPES = ["artist"];
const POPULAR_ARTISTS = [
  "2YZyLoL8N0Wb9xBt1NhZWg",
  "06HL4z0CvFAxyc27GXpf02",
  "74KM79TiuVKeVCqs8QtB0B",
  "4q3ewBCX7sLwd24euuV69X",

  "1vCWHaC5f2uS3yhpwWbIA6",
  "6qqNVTkY8uBg9cP3Jd7DAH",
  "0avMDS4HyoCEP6RqZJWpY2",
  "5eAWCfyUhZtHHtBdNk56l1",

  "4NgNsOXSwIzXlUIJcpnNUp",
  "19k8AgwwTSxeaxkOuCQEJs",
  "3LZZPxNDGDFVSIPqf4JuEf",
  "246dkjvS1zLTtiykXe5h60",
  "6vWDO969PvNqNYHIOW5v0m",
  "2ye2Wgw4gimLv2eAKyk1NB",
  "5M52tdBnJaKSvOpJGz8mfZ",
  "6p2442ymrT9lZEuCZJdYcH",
  "5SHgclK1ZpTdfdAmXW7J6s",
];
const DOMAIN_NAME = "https://music-timeline-backend.fly.dev";
//const DOMAIN_NAME = "http://localhost:3001";

// Create a provider component
export const StateProvider = ({ children }) => {
  const [token, setToken] = useState(
    window.localStorage.getItem("token") || ""
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [artist, setArtist] = useState(null);
  const [artistInfo, setArtistInfo] = useState(null);
  const [artistAlbums, setArtistAlbums] = useState(null);
  const [popularArtists, setPopularArtists] = useState([]);
  const [includeSingels, setIncludeSingels] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [searchOffset, setSearchOffset] = useState(0);
  const [albumOfsett, setAlbumOffset] = useState(0);
  const [messages, setMessages] = useState([]);
  const [isSticky, setIsSticky] = useState(false); // State for sticky class

  const navigate = useNavigate();
  let isRefreshing = false;
  let refreshTokenPromise = null; // Store the promise of token refresh

  const getClientToken = () => {
    if (isRefreshing) {
      return refreshTokenPromise; // If already refreshing, return the ongoing promise
    }

    isRefreshing = true;
    refreshTokenPromise = new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${DOMAIN_NAME}/getSpotifyToken`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        const tokenExpirationTime =
          new Date().getTime() + data.expires_in * 1000; // Store expiration

        setToken(data.access_token);
        window.localStorage.setItem("token", data.access_token);
        window.localStorage.setItem(
          "token_expiration",
          tokenExpirationTime.toString()
        );

        resolve(data.access_token); // Resolve the promise with the new token
      } catch (error) {
        console.error("Error fetching token from backend", error);
        reject(error);
      } finally {
        isRefreshing = false; // Reset the flag
        refreshTokenPromise = null; // Clear the stored promise after refreshing
      }
    });

    return refreshTokenPromise; // Return the promise to ensure subsequent calls wait for it
  };

  const isTokenExpired = () => {
    const tokenExpiration = window.localStorage.getItem("token_expiration");
    const currentTime = new Date().getTime();

    return !tokenExpiration || currentTime >= tokenExpiration; // Token is expired if current time is past expiration
  };

  const ensureValidToken = async () => {
    const isExpired = isTokenExpired();

    if (isExpired) {
      return await getClientToken(); // Refresh token if expired and return it
    }

    return window.localStorage.getItem("token"); // Return the valid token
  };

  const getSearch = async (searchQuery, offset = 0) => {
    if (!searchQuery || searchQuery.length === 0) {
      return; // Exit if the search query is empty or undefined
    }

    try {
      setIsLoading(true);
      const token = await ensureValidToken();

      const response = await fetch(
        `${SEARCH_ENPOINT}?q=${searchQuery}&type=${TYPES.join(
          ","
        )}&offset=${offset}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (offset !== 0) {
        // Combine the existing search results with the new data
        const combinedList = [
          ...(searchResults?.artists?.items || []),
          ...(data?.artists?.items || []),
        ];

        const updatedData = {
          ...data,
          artists: {
            ...data.artists,
            items: combinedList,
          },
        };

        setSearchResults(updatedData); // Set combined data
      } else {
        setSearchResults(data); // Set initial data if no offset
      }

      setArtistAlbums(null);
      setArtist(null);
      navigate(`/search?q=${searchQuery}`);
    } catch (error) {
      if (error.message.includes("401")) {
        console.log("Unauthorized (401): Token might be invalid or expired.");
      } else {
        console.error("Error while fetching artist details:", error);
      }
    } finally {
      // This block will execute whether or not the try block succeeded
      setIsLoading(false); // Stop loading
    }
  };

  /// GET ARTIST //////////////////////////////////////////////////////////
  const getArtist = async (id) => {
    try {
      setIsLoading(true);
      const token = await ensureValidToken();

      const response = await fetch(`${ARTIST_ENPOINT}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json(); // Parse the JSON response

      setArtist(data); // Store the artist information in state
      // You can optionally also fetch albums here if you want

      getArtistInfo(data?.name);

      getArtistAlbums(id); // Fetch albums for the artist
    } catch (error) {
      if (error.message.includes("401")) {
        console.log("Unauthorized (401): Token might be invalid or expired.");
      } else {
        console.error("Error while fetching artist details:", error);
      }
    } finally {
      // This block will execute whether or not the try block succeeded
      setIsLoading(false); // Stop loading
    }
  };

  const getArtistAlbums = async (id, offset = 0) => {
    try {
      const token = await ensureValidToken();

      const response = await fetch(
        `${ARTIST_ENPOINT}/${id}/albums?limit=50&include_groups=album,single&offset=${offset}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        // Handle non-successful HTTP statuses
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json(); // Parse the JSON response

      if (offset !== 0) {
        // Combine existing albums with new data if offset is greater than 0
        const combinedAlbums = [
          ...(artistAlbums?.items || []), // Existing items
          ...(data?.items || []), // New items
        ];

        // Update the state with the combined albums
        setArtistAlbums({
          ...data,
          items: combinedAlbums,
        });
      } else {
        // Set artist albums if no offset
        setArtistAlbums(data);
      }
      
      return data; // Return data for further processing in loadMoreItems
    } catch (error) {
      if (error.message.includes("401")) {
        console.log("Unauthorized (401): Token might be invalid or expired.");
      } else {
        console.error("Error while fetching artist details:", error);
      }
    } finally {
    }
  };


  const getArtistAlbum = async (albumId) => {
    try {
      setIsLoading(true);

      const token = await ensureValidToken();

      const response = await fetch(`${ALBUMS_ENPOINT}/${albumId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        // Handle non-successful HTTP statuses
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json(); // Parse the JSON response
      return data;
    } catch (error) {
      if (error.message.includes("401")) {
        console.log("Unauthorized (401): Token might be invalid or expired.");
      } else {
        console.error("Error while fetching artist details:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getMultipleArtists = async (artistArray) => {
    if (artistArray.length === 0) return [];

    try {
      setIsLoading(true);
      const token = await ensureValidToken();

      const response = await fetch(
        `${ARTIST_ENPOINT}?ids=${artistArray.join(",")}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        // Handle non-successful HTTP statuses
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      return data.artists || []; // Ensure you're returning the artists
    } catch (error) {
      if (error.message.includes("401")) {
        console.log("Unauthorized (401): Token might be invalid or expired.");
      } else {
        console.error("Error fetching multiple artists:", error);
        return []; // Return an empty array in case of other errors
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getArtistInfo = async (artistName) => {
    setIsLoading(true);

    try {
      // Make the request to your Node.js backend
      const response = await fetch(`${DOMAIN_NAME}/artistinfo/${artistName}`);

      const data = await response.json();

      if (response.ok) {
        setArtistInfo(data); // Set artist info if the request is successful
      } else {
        console.error("Error fetching artist info:", data.error);
      }
    } catch (error) {
      console.error("Error while fetching artist info from backend:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (artist?.name) {
      getArtistInfo(artist?.name);
    }
  }, [artist?.name]);

  useEffect(() => {
    if (!token) return; // Ensure the token is available

    const fetchPopularArtists = async () => {
      const fetchArtists = await getMultipleArtists(POPULAR_ARTISTS);
      setPopularArtists(fetchArtists);
    };
    fetchPopularArtists();
  }, [token]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const scrollToTopInstant = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };
  const showToast = (message) => {
    setMessages((prevMessages) => [...prevMessages, { text: message }]);

    // Automatically remove the toast after 5 seconds
    setTimeout(() => {
      setMessages((prevMessages) =>
        prevMessages.filter((msg, index) => index !== 0)
      );
    }, 5500); // 500ms for fade-out + 5000ms for display
  };

  const closeToast = (index) => {
    setMessages((prevMessages) => prevMessages.filter((_, i) => i !== index));
  };

  const context = {
    messages, setMessages,
    showToast,
    closeToast,
    scrollToTop,
    scrollToTopInstant,
    getClientToken,
    token,
    setToken,
    artist,
    artistInfo,
    getArtistInfo,
    setArtist,
    searchTerm,
    setSearchTerm,
    searchResults,
    setSearchResults,
    getSearch,
    artistAlbums,
    getArtistAlbums,
    includeSingels,
    setIncludeSingels,
    popularArtists,
    loading,
    setIsLoading,
    getArtist,
    searchOffset,
    setSearchOffset,
    getArtistAlbum,
    albumOfsett,
    setAlbumOffset,
    isSticky, setIsSticky
  };

  return (
    <StateContext.Provider value={context}>{children}</StateContext.Provider>
  );
};

// Custom hook for using the context
export const useStateContext = () => {
  return useContext(StateContext);
};
