import React, { useState } from 'react';
import classes from './toggle.module.css'; // Import CSS module

const Toggle = ({ setState, defaultState }) => {
  const [isToggled, setIsToggled] = useState(defaultState);

  const handleToggle = () => {
    const newState = !isToggled;
    setIsToggled(newState);
    setState(newState); // Update the parent state
  };

  return (
    <div className={`${classes.toggle} ${isToggled ? classes.toggled : ''}`} onClick={handleToggle}>
      <div className={`${classes.toggleCircle} ${isToggled ? classes.toggled : ''}`}></div>
    </div>
  );
};

export default Toggle;
