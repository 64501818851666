import React, { useEffect, useRef, useCallback, useState } from "react";
import classes from "./list.module.css";
import ListItem from "./listItem";
import { useStateContext } from "../../context/context";

function List(props) {
  const {
    searchResults,
    searchTerm,
    loading,
    shimmerCount = 1,
    setSearchOffset,
    getSearch,
    searchOffset,
    scrollToTop
  } = useStateContext();
  
  useEffect(() => {
    scrollToTop();
  }, []);

  const {
    label = "Search results",
    items = searchResults?.artists?.items,
    maxItems = 100,
  } = props;

  const [loadingMore, setLoadingMore] = useState(false);

  const observerRef = useRef();
  const debounceTimeoutRef = useRef();

  // Get the total number of items available from the search
  const totalItems = searchResults?.artists?.total || 0;
  const hasMoreItems = totalItems > items?.length;

  // Debounced function to handle the loading of more items
  const loadMoreItems = useCallback(() => {
    if (loading || loadingMore || !hasMoreItems) return; // Prevent unnecessary calls if already loading or no more items
  
    setSearchOffset((prevOffset) => {
      const newOffset = prevOffset + 20;
  
      // Check if the new offset exceeds the maximum allowed items
      if (newOffset >= maxItems) {
        setLoadingMore(false); // Stop loading if we reach or exceed the limit
        return prevOffset; // Return previous offset since we won't fetch more
      }
  
      // Trigger loading more items and the API call
      setLoadingMore(true);
      getSearch(searchTerm, newOffset).finally(() => {
        setLoadingMore(false);
      });
  
      return newOffset;
    });
  }, [loading, loadingMore, searchTerm, setSearchOffset, getSearch, hasMoreItems, maxItems]);
  

  const lastItemRef = useCallback(
    (node) => {
      if (label !== "Search results") return;
      if (loading || loadingMore || !hasMoreItems) return; // Guard clause
      if (observerRef.current) observerRef.current.disconnect(); // Disconnect old observer

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // Clear the previous debounce timeout if still active
          if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
          }
          // Debounce the loadMoreItems call
          debounceTimeoutRef.current = setTimeout(loadMoreItems, 300);
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [
      loading,
      loadingMore,
      searchTerm,
      setSearchOffset,
      getSearch,
      hasMoreItems,
      label,
      loadMoreItems,
    ]
  );

  const noItems =
    searchTerm?.length > 0 ? (
      <p className={classes.emptyMessage}>No items to display</p>
    ) : null;

  const shimmerEffect = (
    <div className={classes.shimmerArray}>
      {Array.from({ length: shimmerCount }).map((_, index) => (
        <div
          key={index}
          className={`${classes.shimmerContainer} ${classes.shimmer}`}
        ></div>
      ))}
    </div>
  );

  return (
    <div className={classes.container}>
      {loading && searchOffset === 0 && label === "Search results"? (
        shimmerEffect
      ) : items && items.length > 0 ? (
        <>
          <h2>{label}</h2>
          <ul className={classes.listContainer}>
            {items.map((item, index) => {
              if (index === items.length - 1) {
                return (
                  <li ref={lastItemRef} key={index}>
                    <ListItem item={item} />
                  </li>
                );
              } else {
                return <li key={index}><ListItem item={item}  /></li>;
              }
            })}
            {loadingMore && hasMoreItems && label === "Search results" && shimmerEffect}
          </ul>
        </>
      ) : (
        null
      )}
      {hasMoreItems && label === "Search results" && (
        <p className={classes.endMessage}>
          You can scroll forever, or try another search term . . .
        </p>
      )}
    </div>
  );
}

export default List;
